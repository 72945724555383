import React from 'react'  
import update from 'immutability-helper'
import {
    FormLayout,
    Stack,
    RadioButton,
    TextField,
    TextContainer,
    TextStyle,
    Checkbox,
  } from "@shopify/polaris"
import FeaturedImage from './featuredImage';
function Customization(props) {
    const { state, setState, checkoutOfferType, currencySymbol } = props;
    const handleLayoutChange = (_checked, newValue) => {
      if("row" === newValue) {
        setState(update(state, { layout: { $set: newValue }, generalLayout: { $set: 'tiles' }}))
      }else{
        setState(update(state, { layout: { $set: newValue }}))
      }
    }
    const handleGeneralLayoutChange = (_checked, newValue) => {
      if("tiles" !== newValue && "row" === state.layout) {
        setState(update(state, { layout: { $set: 'carousel' },  generalLayout: { $set: newValue }}))
      }else{
        setState(update(state, { generalLayout: { $set: newValue }}))
      }
    }
    const handleAddToCartActionChange = (_checked, newValue) => {
      setState(update(state, { addToCartAction: { $set: newValue }}))
    }
    const handleShowQuantitySelectorChange = (newValue) => {
      setState(update(state, { showQuantitySelector: { $set: newValue }}))
    }
    const handleBorderChange = (_checked, newValue) => {
      setState(update(state, { border: { $set: newValue }}))
    }
    return (
      <>
        <FormLayout>
          <div style={{margin:'5px p'}}>
              <TextContainer spacing="loose">
                <TextStyle variation="strong">Add to cart action</TextStyle>
              </TextContainer>
              <Stack>
                <RadioButton
                  label="Button"
                  checked={state.addToCartAction === null || state.addToCartAction === 'button'}
                  id="button"
                  name="addToCartAction"
                  onChange={handleAddToCartActionChange}
                />
                <RadioButton
                  label="Toggle"
                  id="toggle"
                  name="addToCartAction"
                  checked={state.addToCartAction === 'toggle'}
                  onChange={handleAddToCartActionChange}
                />
                <RadioButton
                  label="Checkbox"
                  id="checkbox"
                  name="addToCartAction"
                  checked={state.addToCartAction === 'checkbox'}
                  onChange={handleAddToCartActionChange}
                />
              </Stack>
          </div>
          {
            ((Array.isArray(state.product) && state.product.length > 1) || state.productNumber > 1) ?
            <div className='layout-step'>
              <TextContainer spacing="loose">
                <TextStyle variation="strong">Product layout</TextStyle>
              </TextContainer>
              <Stack>
                <RadioButton
                  label="Carousel"
                  checked={state.layout === 'carousel'}
                  id="carousel"
                  name="layout"
                  disabled={'tiles' === state.generalLayout}
                  onChange={handleLayoutChange}
                />
                <RadioButton
                  label="Stacked"
                  id="stacked"
                  name="layout"
                  disabled={'tiles' === state.generalLayout}
                  checked={state.layout === 'stacked'}
                  onChange={handleLayoutChange}
                />
                <RadioButton
                  label="Row"
                  id="row"
                  name="layout"
                  checked={state.layout === 'row'}
                  onChange={handleLayoutChange}
                />
              
              </Stack>
              <Stack>
                  <div style={{padding: '10px 10px 0 10px', opacity: 'tiles' === state.generalLayout? '0.5':'1'}}>
                  <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Carousel_icon.svg?alt=media&token=5ff293de-fef5-440a-944b-5abc8a4936e6" alt="carousel layout" />
                  </div>
                  <div style={{padding: '10px 10px 0 10px', opacity: 'tiles' === state.generalLayout? '0.5':'1'}}>
                  <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Stack_icon.svg?alt=media&token=d00cbd98-93ed-4cdf-8a12-7386ecf59297" alt="stack layout" />
                  </div>
                  <div style={{padding: '10px 10px 0 0'}}>
                  <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Row_icon.svg?alt=media&token=ff46b131-139e-4c5b-83d9-a75e7a3123f2" alt="row layout" />
                  </div>
              </Stack>
            </div>
            :
            false
          }
          <div style={{marginTop:'5px'}}>
            <TextContainer spacing="loose">
              <TextStyle variation="strong">General layout</TextStyle>
            </TextContainer>
            <Stack>
                <RadioButton
                  label="Standard"
                  checked={state.generalLayout === 'standard'}
                  id="standard"
                  name="generalLayout"
                  onChange={handleGeneralLayoutChange}
                />
                <RadioButton
                  label="Center"
                  id="center"
                  name="generalLayout"
                  checked={state.generalLayout === 'center'}
                  onChange={handleGeneralLayoutChange}
                />
                {((Array.isArray(state.product) && state.product.length > 1) || state.productNumber > 1) &&
                <RadioButton
                  label="Tiles"
                  id="tiles"
                  name="generalLayout"
                  disabled={'row' !== state.layout}
                  checked={state.generalLayout === 'tiles'}
                  onChange={handleGeneralLayoutChange}
                />
                }
            </Stack>
          </div>
          <div style={{marginTop:'5px'}}>
              <Checkbox
                label="Show quantity selector"
                checked={state.showQuantitySelector}
                onChange={handleShowQuantitySelectorChange}
              />
          </div>
          <div style={{margin: '3rem 0'}} className='second-step'>
          <FormLayout>
            <TextField
              label="Callout Banner (optional)"
              placeholder='e.g. “Frequently Bought together”'
              onChange={(val) => {
                setState(update(state, { banner: { $set: val }}))
              }}
              value={state.banner}
            />
            {/*"ai" === checkoutOfferType &&
              <TextField 
                label="Set a title for all auto upsells" 
                placeholder="Product title" 
                onChange={(val) => setState(update(state, { title: { $set: val }})) }
                value={state.title}
                />   
            */}
            {"ai" === checkoutOfferType &&
              <TextField 
                label="Set a description for all auto upsells" 
                placeholder="Product subtitle" 
                onChange={(val) => setState(update(state, { subtitle: { $set: val }})) }
                value={state.subtitle}
                />   
            }
            {"ai" === checkoutOfferType &&
              <p>Dynamic variables you can use: [cart product title], [cart variant title], [upsell product title], [upsell variant title]</p>  
            } 
          </FormLayout>
          </div>
          <TextContainer spacing="loose">
                <TextStyle variation="strong">Border</TextStyle>
              </TextContainer>
              <Stack>
                <RadioButton
                  label="Individual product"
                  id="individualProduct"
                  name="border"
                  checked={(state.border === null && 'row' !== state.layout) || state.border === 'individualProduct'}
                  onChange={handleBorderChange}
                />
                { 'carousel' !== state.layout &&
                <RadioButton
                  label="By group"
                  id="byGroup"
                  name="border"
                  checked={state.border === 'byGroup'}
                  onChange={handleBorderChange}
                />
                }
                <RadioButton
                  label="Hide border"
                  checked={(state.border === null && 'row' === state.layout) || state.border === 'hideBorder'}
                  id="hideBorder"
                  name="border"
                  onChange={handleBorderChange}
                />
              </Stack>
        </FormLayout>

        {
          "manual" === checkoutOfferType &&
            <FeaturedImage state={state} setState={setState}/>
        }
        </>
    )
}

export default Customization;